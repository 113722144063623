#main {
  width: 100vw;
  height: calc(100vh - 56px);
  /* overflow: scroll; */
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
}

#main.black {
  background-color: rgba(33,37,41,1)!important;
}

#main.advice {
  overflow-y: scroll;
}

#content {
  width: 100%;
}

#advice-table {
  font-size: 11pt;
  border: solid 1px #000;
}
#advice-table tr td {
  border: solid 1px #000;
}
#advice-table .btn {
  height: 40px;
  font-size: 10pt;
}
.aiAdviceTextArea, .aiImageAdviceTextArea {
  border: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.aiAdvicePrint, .aiImageAdvicePrint {
  width: 100%;
  height: 100%;
}

#printBtn {
  margin: 1em;
}

@media screen {
  #advice-table {
    font-size: 10pt;
  }
  #advice-table .btn {
    font-size: 8pt;
  }
}

@media print {
  @page {
    size: A4 portrait;
  }
  body, html {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    overflow: hidden !important;
  }
  #main.advice {
    overflow: hidden !important;
  }
  #navbar, #printBtn {
    display: none !important;
  }
  .container {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
  #advice-table {
    font-size: 10pt;
  }
  #advice-table .btn {
    font-size: 8pt;
  }
}
